/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

@import "colors";

.container {
  max-width: 1200px;
}

body {
  font-family: open sans, sans-serif;
  background-color: #222222;

  .vertical-social-media {
    position: fixed;
    right: 1rem;
    top: 3rem;
    z-index: 1020;

    > ul {
      margin: 0 5px;
      list-style-type: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 60px;

      > li {
        padding: 6px 0;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;

        a {
          background: white;
          height: 35px;
          width: 35px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          border-radius: 10px;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          text-decoration: none;

          &.twitter {
            background-color: $twitter;
          }

          &.facebook {
            background-color: $facebook;
          }

          &.youtube {
            background-color: $youtube;
          }

          &.instagram {
            background: $instagram;
          }

          &.tiktok {
            background-color: $tiktok-square;
          }

          &.discord {
            background-color: $discord;
          }

          > i {
            color: white;
          }
        }

        &:hover {
          opacity: 0.8;
          padding-right: 10px;
        }
      }
    }
  }

  nav {
    background: $navigation;
    border-bottom: 1px solid;
    -webkit-box-shadow: 0 5px 8px 2px #21252d;
    box-shadow: 0 5px 8px 2px #21252d;

    .right-menu {
      flex-grow: 1;
      justify-content: end;
      gap: 20px;
      display: flex;
      @media screen and (max-width: 991px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 20px;
        > div {
          display: block !important;

          .r {
            margin-top: 15px;
          }
        }
        span, a {
          display: block;
        }
      }

      span, a {
        cursor: pointer;
        //color: $main-color;
        color: $sand;
        text-decoration: none;
        font-size: 20px;
        padding-right: 10px;
        font-weight: bold;
        -webkit-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;

        &:hover {
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }

  section {
    height: 100vh;

    > h2 {
      //color: $green;
      font-weight: bold;
      text-align: center;
      padding: 2rem;
      font-size: 2rem;
      position: relative;
      background-color: #2b313e;
      //-webkit-box-shadow: 0 4px 2px -2px;
      //box-shadow: rgba(34, 40, 49, 25%) 0px 13px 27px -5px, rgba(0, 0, 0, 30%) 0px 8px 16px -8px;
      margin-bottom: 25px;
      opacity: 0.85;
      text-transform: uppercase;

    }

    &#top {
      //background: $background-main;
      .top-inner {
        @media screen and (max-width: 640px) {
          gap: 0 !important;
        }
      }

      .video {
        position: absolute;
        left: 50%;
        width: 70%;
        height: 70vh;
        -webkit-transform: translate(-50%, 20%);
        -ms-transform: translate(-50%, 20%);
        transform: translate(-50%, 20%);
        @media screen and (max-width: 1199px) {
          width: 95%;
        }
      }

      .order-now {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        bottom: -10%;
        left: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 10px;
        -webkit-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;

        &:hover {
          opacity: 0.78;
          cursor: pointer;
        }

        a {
          width: 300px;
          height: 100px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          text-decoration: none;
          font-size: 20px;
          font-weight: bold;
          max-width: calc(100% - 20px);
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          color: white;
        }
      }
    }

    &#about {
      //background: $background-main;
      padding-top: 6rem;
      height: unset;
      min-height: 100vh;

      > div {
        font-family: cursive;
        font-size: 18px;
      }

      img {
        width: 100%;
        max-width: 100%;
      }

      .features-header {
        color: $sand;
        font-weight: bold;
        font-size: 2rem;
      }

      .features {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: minmax(0, 1fr) 25px minmax(0, 1fr) 25px minmax(0, 1fr);
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 25px;
        width: 100%;
        background-color: $background-second;
        padding: 30px 15px 15px;
        border-radius: 10px;

        svg {
          font-size: 1.5rem;
          color: $sand;
          padding: 4px;
          border-radius: 5px;
          -webkit-box-shadow: 0 0 11px $sand;
          box-shadow: 0 0 11px $sand;
        }

        h2 {
          margin-top: 5px;
          color: $main-color;
        }
      }
    }

    &#demo {
      //background: $background-main;
      //min-height: 100vh;
      height: unset;

      .demo-gifs {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: minmax(0, 1fr) 25px minmax(0, 1fr) 25px minmax(0, 1fr);
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 25px;
        width: 100%;

        img {
          -webkit-box-shadow: 3px 3px 8px #000;
          box-shadow: 3px 3px 8px #000;
          border-radius: 5px;
        }
      }

      .get-demo {
        text-align: center;
        padding-top: 40px;
        margin-bottom: 60px;

        a {
          color: white;
          font-size: 22px;
          text-decoration: none;
          cursor: pointer;
          -webkit-transition: 0.2s ease-in-out;
          -o-transition: 0.2s ease-in-out;
          transition: 0.2s ease-in-out;
          padding: 20px;
          border-radius: 10px;

          &:hover {
            opacity: 0.75;
          }
        }
      }
    }

    &#media {
      //background: $background-main;
      height: unset;
      min-height: 100vh;
      display: flex;
      align-items: center;
      position: relative;

      > h2 {
        position: absolute;
        top: 0;
        width: 100%;
      }

      .gallery {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: minmax(0, 1fr) 25px minmax(0, 1fr) 25px minmax(0, 1fr);
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 25px;
        width: 100%;
        padding-bottom: 40px;

        .media-item {
          -webkit-box-shadow: 3px 3px 8px #000;
          box-shadow: 3px 3px 8px #000;
          overflow: hidden;
          position: relative;
          border-radius: 5px;

          .title {
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: 9;
            padding: 5px 10px;
            background: rgba(1, 1, 1, 0.7);

            span {
              color: white;
            }
          }

          img {
            max-width: 100%;

            &:hover {
              -webkit-transform: scale(1.1);
              -ms-transform: scale(1.1);
              transform: scale(1.1);
            }
          }
        }
      }
    }

    &#faq {
      //background: $background-main;
      .accordion-button {
        background-color: rgba(255, 255, 255, 0.1) !important;
        color: white;
        border: 1px solid;

        &:after {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
      }

      .accordion-item {
        border-bottom: 0;
      }

      .accordion-button:focus {
        box-shadow: none;
      }

      .accordion-button:not(.collapsed) {
        color: white;
      }

      height: unset;
      min-height: 100vh;

      > div > h2 {
        font-size: 2rem;
        font-weight: bold;
      }

      ul {
        list-style-type: auto;
        margin-bottom: 50px;
        padding-left: 27px;

        li {
          a {
            font-size: 18px;
            text-decoration: none;
            color: $main-color;
          }
        }
      }

      .faq-answer {
        margin-bottom: 25px;

        h2 {
          color: $sand;
          font-size: 20px;
        }

        p {
          font-family: cursive;
          font-size: 18px;
        }

      }

      a {
        font-size: 18px;
        text-decoration: none;
        color: $main-color;
      }
    }
  }

  footer {
    //background: $background-main;
    padding-bottom: 8rem;

    #social-media {

      h2 {
        letter-spacing: 2px;
        font-weight: bold;
        color: $main-color;
      }

      ul {
        list-style-type: none;
        //margin-top: 1.5rem !important;

        li {
          padding: 0 10px;
          display: inline-block;

          a {
            height: 35px;
            width: 35px;
            display: flex;
            border-radius: 10px;
            align-items: center;
            justify-content: center;
            text-decoration: none;

            &:hover {
              opacity: 0.75;
            }
          }

          &:first-child {
            padding-left: 0;
          }
        }

      }
    }

    #contact {
      h2 {
        letter-spacing: 2px;
        font-weight: bold;
        color: $sand;
      }
    }
  }
}

#copyright {
  //background: $background-main;

  .wrapper {
    padding: 10px 0;

    p {
      font-size: 11px;
    }
  }
}

a {

  &.twitter {
    background-color: $twitter;
  }

  &.facebook {
    background-color: $facebook;
  }

  &.youtube {
    background-color: $youtube;
  }

  &.instagram {
    background: $instagram;
  }

  &.tiktok {
    background-color: $tiktok-square;
  }

  &.discord {
    background-color: $discord;
  }

  > svg {
    color: white;
  }

}

//.fa-facebook, .fa-facebook-square {
//  color: $facebook;
//}
//
//.fa-twitter, .fa-twitter-square {
//  color: $twitter;
//}
//
//.fa-youtube, .fa-youtube-play, .fa-youtube-square {
//  color: $youtube;
//}
//
//.fa-instagram {
//  color: white;
//  background: $instagram;
//  border-radius: 3px;
//  padding: 0 2px;
//}
//
//.fa-discord {
//  color: $discord;
//}

.fa-tiktok {
  color: #ffffff;
  -webkit-filter: $tiktok;
  filter: $tiktok;
}

.fs-1_5 {
  font-size: 1.5rem;
}

.fs-0_8 {
  font-size: 0.8rem;
}

.m-p-g {
  max-width: 100%;

  .m-p-g__controls {
    z-index: 9999;
  }
}

#scroll-up {
  position: fixed;
  right: 15px;
  bottom: 15px;
  font-size: 35px;
  z-index: 9;

  p {
    cursor: pointer;
    color: #e8b572;

    &:hover {
      opacity: 0.8;
    }
  }
}

.pt-5r {
  padding-top: 5rem;
}

.m-p-g__btn {
  background: $main-color;

  &:hover {
    opacity: 0.75;
    background: $main-color;
  }
}

.faq-top-bg {
  height: 1080px;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  z-index: 0;
  opacity: 0.09;
  box-shadow: inset 0 0 10px #000000;
}

.top-button {
  font-size: 20px;
  color: white;
  border-width: 2px;
  border-radius: 5px;
  height: 100px;
  width: 300px;

  &:hover, &:active, &:focus {
    background-color: rgba(1, 1, 1, 0.3) !important;
  }
}

.modal-50w {
  width: 50%;
  max-width: none !important;
  @media only screen and (max-width: 1199px) {
    width: 100%;
  }
}

.modal-dialog {
  .modal-content {
    background: none;
    border: none;

    .modal-header {
      border: none;
      display: flex;
      justify-content: end;
      padding: 0 0 20px;
    }

    .modal-body {
      padding: 0;

      .yt-iframe {
        padding: 0;
      }
    }
  }
}

.fancy {
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    background-image: url("data:image/svg+xml,%3Csvg width='2250' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%2300A080' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%2300FDCF'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2300FDCF'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23008060'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 50% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 4vh;
  }
}

.footer-list {
  list-style: none;

  li {
    margin: 6px 0;

    a {
      text-decoration: none;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 16px;
      transition: 0.1s ease-in-out;

      &:hover {
        color: #e8b572;
      }
    }
  }
}

.swiper-wrapper {
  display: flex;
  height: 100%;
  align-items: center;

  .title-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -10px;

    .title-inner {
      background: rgba(1, 1, 1, 0.8);
      margin: 0 auto;
      padding: 0.5rem;
      max-width: 250px;
      color: white;
      border-top: 1px solid;
      border-color: #950f90;
    }
  }
}

@media only screen and (max-width: 768px) {
  .gallery, .demo-gifs, .features {
    -ms-grid-columns: minmax(0, 1fr) 25px minmax(0, 1fr) !important;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

@media only screen and (max-width: 467px) {
  .gallery, .demo-gifs, .features {
    -ms-grid-columns: minmax(0, 1fr) !important;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}