/*======= 3. BUTTONS STYLES  =======*/
.btn-radius {
  border-radius: 10px !important;
}

.btn {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 2px;
  padding: 13px 28px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
  text-transform: uppercase;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.btn-sm {
  font-size: 11px;
  font-weight: 600;
  padding: 11px 25px;
}

.btn-lg {
  padding: 18px 49px;
}

.btn-xs {
  font-size: 10px;
  padding: 8px 14px;
}

.btn-primary {
  background: #5a7ce2;
  background: -moz-linear-gradient(-45deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  background: -webkit-linear-gradient(-45deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  background: linear-gradient(135deg, #5a7ce2 0%, #8283e8 50%, #5c5de8 51%, #565bd8 71%, #575cdb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5a7ce2', endColorstr='#575cdb', GradientType=1);
  background-size: 400% 400%;
  /* -webkit-animation: AnimationName 3s ease infinite; */
  -moz-animation: AnimationName 3s ease infinite;
  /* animation: AnimationName 3s ease infinite; */
  /* -webkit-animation: AnimationName 3s ease infinite; */
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
}

.btn-secondary {
  background: #797b7f;
  background: -moz-linear-gradient(-45deg, #797b7f 0%, #b5b8bf 50%, #8e9397 51%, #8e9397 71%, #828589 100%);
  background: -webkit-linear-gradient(-45deg, #797b7f 0%, #b5b8bf 50%, #8e9397 51%, #8e9397 71%, #828589 100%);
  background: linear-gradient(135deg, #797b7f 0%, #b5b8bf 50%, #8e9397 51%, #8e9397 71%, #828589 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#797b7f', endColorstr='#828589', GradientType=1);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
}

.btn-success {
  background: #05ac50;
  background: -moz-linear-gradient(-45deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  background: -webkit-linear-gradient(-45deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  background: linear-gradient(135deg, #05ac50 0%, #21dd72 50%, #05c44e 51%, #05ac50 71%, #05ac50 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#05ac50', endColorstr='#05ac50', GradientType=1);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
}

.btn-danger {
  background: #e81216;
  background: -moz-linear-gradient(-45deg, #fb265b 0%, #fe285b 50%, #f6290c 51%, #fb265b 71%, #fb265b 100%);
  background: -webkit-linear-gradient(-45deg, #fb265b 0%, #f45355 50%, #f6290c 51%, #fb265b 71%, #fb265b 100%);
  background: linear-gradient(135deg, #fb265b 0%, #f45355 50%, #f6290c 51%, #fb265b 71%, #fb265b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e81216', endColorstr='#fc1b21', GradientType=1);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
}

.btn-warning {
  background: #e5ae09;
  background: -moz-linear-gradient(-45deg, #e8b572 0%, #ffd044 50%, #ffc107 51%, #fc9014 71%, #f1890b 100%);
  background: -webkit-linear-gradient(-45deg, #e8b572 0%, #ffd044 50%, #ffc107 51%, #fc9014 71%, #f1890b 100%);
  background: linear-gradient(135deg, #e8b572 0%, #ffd044 50%, #ffc107 51%, #fc9014 71%, #f1890b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e5ae09', endColorstr='#f1890b', GradientType=1);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
  color: #fff !important;
}

.btn-info {
  background: #01a8c1;
  background: -moz-linear-gradient(-45deg, #01a8c1 0%, #2adbf7 50%, #00b5d1 51%, #0aafc9 71%, #0599b1 100%);
  background: -webkit-linear-gradient(-45deg, #01a8c1 0%, #2adbf7 50%, #00b5d1 51%, #0aafc9 71%, #0599b1 100%);
  background: linear-gradient(135deg, #01a8c1 0%, #2adbf7 50%, #00b5d1 51%, #0aafc9 71%, #0599b1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#01a8c1', endColorstr='#0599b1', GradientType=1);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
}

.btn-light {
  background: #f2f2f2;
  background: -moz-linear-gradient(-45deg, #f2f2f2 0%, #dddddd 50%, #ffffff 51%, #ffffff 71%, #f6f8fb 100%);
  background: -webkit-linear-gradient(-45deg, #f2f2f2 0%, #dddddd 50%, #ffffff 51%, #ffffff 71%, #f6f8fb 100%);
  background: linear-gradient(135deg, #f2f2f2 0%, #dddddd 50%, #ffffff 51%, #ffffff 71%, #f6f8fb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f2f2', endColorstr='#f6f8fb', GradientType=1);
  color: #3f345f !important;
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
}

.btn-dark {
  background: #343a40;
  background: -moz-linear-gradient(-45deg, #343a40 0%, #667584 50%, #4e5256 51%, #242a30 71%, #343a40 100%);
  background: -webkit-linear-gradient(-45deg, #343a40 0%, #667584 50%, #4e5256 51%, #242a30 71%, #343a40 100%);
  background: linear-gradient(135deg, #343a40 0%, #667584 50%, #4e5256 51%, #242a30 71%, #343a40 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#343a40', endColorstr='#343a40', GradientType=1);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
  border: medium none;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 31%
  }
  50% {
    background-position: 100% 70%
  }
  100% {
    background-position: 0% 31%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 31%
  }
  50% {
    background-position: 100% 70%
  }
  100% {
    background-position: 0% 31%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 31%
  }
  50% {
    background-position: 100% 70%
  }
  100% {
    background-position: 0% 31%
  }
}