$main-color: #FF2E63;
$green: #5fbb74;
$sand: #e8b572;

$background-main: repeating-radial-gradient(#2c323e, #22242a 100%);
//repeating-radial-gradient(#2c323e, #22242a 100%)
$background-second: #2b313e;
//repeating-linear-gradient(45deg, #3d4964, #22242a 100%)

$navigation: rgba(1, 1, 1, 0.75);

$btn: linear-gradient(180deg, #fb265b, transparent);

// social media
$twitter: #00aced;
$facebook: #3b5998;
$youtube: #bb0000;
$instagram: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
$tiktok-square: black;
$tiktok:drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
$discord: #5865f2;

